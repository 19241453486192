import { useState, useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import {  useSelector } from "react-redux";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { useParams } from "react-router-dom";

import {
	useUpdateUserMutation,
	useGetUserDetailsQuery,
	useUploadUserImageMutation,
} from "../slices/usersApiSlice";
import { toast } from "react-toastify";

const UserEditScreen = () => {
	const { id: userId } = useParams();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [image, setImage] = useState("");
	const [altContact, setAltContact] = useState("");

	const [uploadUserImage, { isLoading: loadingUpload }] =
		useUploadUserImageMutation();

	const navigate = useNavigate();
	const [updateUser, { isLoading: loadingUpdate }] = useUpdateUserMutation();

	const { userInfo } = useSelector((state) => state.auth);
	if (userInfo._id !== userId) {
		navigate(`/users/edit/${userInfo._id}`);
	}

	const {
		data: user,
		isLoading: loadingGetUser,
		error,
		refetch,
	} = useGetUserDetailsQuery(userId);

	const submitHandler = async (e) => {
		e.preventDefault();
		console.log(userId);
		if (password !== confirmPassword) {
			toast.error("密码不匹配");
		} else {
			try {
				const res = await updateUser({
					userId,
					password,
					image,
					altContact,
				}).unwrap();
				toast.success("用户信息已修改");
				refetch();
				navigate(`/users/${userInfo._id}`);
			} catch (err) {
				toast.error(err?.data?.message || err.error);
			}
		}
	};
	const uploadFileHandler = async (e) => {
		const formData = new FormData();
		formData.append("image", e.target.files[0]);
		try {
			const res = await uploadUserImage(formData).unwrap();
			toast.success(res.message);
			setImage(res.image);
		} catch (err) {
			toast.error(err?.data?.message || err.error);
		}
	};
	useEffect(() => {
		if (user) {
			setEmail(user.email);
			setImage(user.image);
			setAltContact(user.altContact);
		}
	}, [user]);
	return (
		<FormContainer>
			<h1>修改个人信息</h1>
			<Form onSubmit={submitHandler}>
				<Form.Group className="my-2" controlId="email">
					<Form.Label>电子邮件</Form.Label>
					<Form.Control
						type="email"
						placeholder="只能用@ruc.edu.cn邮箱注册"
						value={email}
						disabled
					></Form.Control>
				</Form.Group>

				<Form.Group className="my-2" controlId="password">
					<Form.Label>新的密码</Form.Label>
					<Form.Control
						type="password"
						placeholder="要改密码的选填"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					></Form.Control>
				</Form.Group>
				<Form.Group className="my-2" controlId="confirmPassword">
					<Form.Label>确认新的密码</Form.Label>
					<Form.Control
						type="password"
						placeholder="要改密码的选填"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group controlId="image">
					<Form.Label>微信用户二维码</Form.Label>
					<Form.Control
						type="text"
						placeholder="修改微信二维码选填"
						value={image}
						onChange={(e) => setImage(e.target.value)}
						readOnly={true}
					></Form.Control>
					<Form.Control
						label="选择图片文件"
						onChange={uploadFileHandler}
						type="file"
					></Form.Control>
					{loadingUpload && <Loader />}
				</Form.Group>
				<Form.Group className="my-2" controlId="altContact">
					<Form.Label>其他联系方式</Form.Label>
					<Form.Control
						type="text"
						placeholder="修改其他联系方式"
						value={altContact}
						onChange={(e) => setAltContact(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Button
					disabled={loadingGetUser || loadingUpdate}
					type="submit"
					variant="primary"
				>
					修改信息
				</Button>

				{loadingGetUser || loadingUpdate ? <Loader /> : null}
			</Form>
		</FormContainer>
	);
};

export default UserEditScreen;

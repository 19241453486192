import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
	return (
		<footer>
			<Container>
				<Row>
					<Col className="text-center py-3">
						<p>bouquiniste团队出品</p>
						<p>有建议或意见欢迎给我们的客服发邮件:hanbin.guo@kedgebs.com</p>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};
export default Footer;

import { DEMANDS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const demandsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDemands: builder.query({
      query: ({ keyword, pageNumber }) => ({
        url: DEMANDS_URL,
        params: { keyword, pageNumber },
      }),
      keepUnusedDataFor: 5,
    }),
    getDemandDetails: builder.query({
      query: (demandId) => ({
        url: `${DEMANDS_URL}/${demandId}`,
      }),
      keepUnusedDataFor: 5,
    }),
    uploadDemandImage: builder.mutation({
      query: (data) => ({
        url: `/api/upload`,
        method: "POST",
        body: data,
      }),
    }),
    newDemand: builder.mutation({
      query: (data) => ({
        url: `${DEMANDS_URL}`,
        method: "POST",
        body: data,
      }),
    }),
    getUserDemands: builder.query({
      query: (userId) => ({
        url: `${DEMANDS_URL}/useritems/${userId}`,
      }),
      keepUnusedDataFor: 5,
    }),
    deleteDemand: builder.mutation({
      query: (demandId) => ({
        url: `${DEMANDS_URL}/${demandId}`,
        method: "DELETE",
      }),
    }),
    updateDemand: builder.mutation({
      query: (data) => ({
        url: `${DEMANDS_URL}/${data.demandId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Demand"],
    }),
  }),
});

export const {
  useGetDemandsQuery,
  useGetDemandDetailsQuery,
  useUploadDemandImageMutation,
  useNewDemandMutation,
  useGetUserDemandsQuery,
  useDeleteDemandMutation,
  useUpdateDemandMutation,
} = demandsApiSlice;

import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { useForgetPasswordMutation } from "../slices/usersApiSlice";
import { toast } from "react-toastify";

const ForgetScreen = () => {
	const navigate = useNavigate();
	const [forget, { isLoading }] = useForgetPasswordMutation();
	const { userInfo } = useSelector((state) => state.auth);
	const [email, setEmail] = useState("");

	useEffect(() => {
		if (userInfo) {
			navigate("/");
		}
	}, [navigate, userInfo]);
	const submitHandler = async (e) => {
		e.preventDefault();
		try {
			const res = await forget({ email }).unwrap();
			toast.success(res.message);
		} catch (err) {
			toast.error(err?.data?.message || err.error);
		}
	};

	return (
		<FormContainer>
			<h1>忘记密码？</h1>

			<Form onSubmit={submitHandler}>
				<Form.Group className="my-2" controlId="email">
					<Form.Label>Email Address</Form.Label>
					<Form.Control
						type="email"
						placeholder="Enter email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Button disabled={isLoading} type="submit" variant="primary">
					获取重置密码的链接
				</Button>

				{isLoading && <Loader />}
			</Form>
		</FormContainer>
	);
};

export default ForgetScreen;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { useParams } from "react-router-dom";

import {
  useGetProductDetailsQuery,
  useUpdateProductMutation,
  useUploadProductImageMutation,
} from "../slices/productsApiSlice";
import { toast } from "react-toastify";

const ProductEditScreen = () => {
  const { id: productId } = useParams();
  const {
    data: product,
    isLoading: loadingGetProduct,
    error,
  } = useGetProductDetailsQuery(productId);

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [uploadProductImage, { isLoading: loadingUpload }] =
    useUploadProductImageMutation();
  const navigate = useNavigate();

  const [updateProduct, { isLoading: loadingUpdate }] =
    useUpdateProductMutation();

  const { userInfo } = useSelector((state) => state.auth);
  useEffect(() => {
    if (product) {
      setName(product.name);
      setImage(product.image);
      setPrice(product.price);
      setDescription(product.description);
    }
  }, [product]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (product.user !== userInfo._id) {
      toast.error("只可以修改自己的商品信息");
      navigate(`//useritems/${userInfo._id}`);
    }
    try {
      const res = await updateProduct({
        productId,
        name,
        price,
        description,
        image,
      }).unwrap();
      toast.success("已成功修改商品信息");
      navigate("/");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };
  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const res = await uploadProductImage(formData).unwrap();
      toast.success(res.message);
      setImage(res.image);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };
  return (
    <FormContainer>
      <h1>修改商品信息</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group className="my-2" controlId="name">
          <Form.Label>商品名称</Form.Label>
          <Form.Control
            type="text"
            placeholder="输入商品名称"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className="my-2" controlId="price">
          <Form.Label>价格</Form.Label>
          <Form.Control
            type="text"
            placeholder="请输入数字（人民币为单位）"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="image">
          <Form.Label>商品图片</Form.Label>
          <Form.Control
            type="text"
            placeholder="在下面上传图片文件"
            value={image}
            onChange={(e) => setImage(e.target.value)}
            readOnly={true}
          ></Form.Control>
          <Form.Control
            label="选择文件"
            onChange={uploadFileHandler}
            type="file"
          ></Form.Control>
          {loadingUpload && <Loader />}
        </Form.Group>
        <Form.Group className="my-2" controlId="description">
          <Form.Label>商品描述</Form.Label>
          <Form.Control
            type="text"
            placeholder="输入商品描述"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button
          disabled={loadingGetProduct || loadingUpdate}
          type="submit"
          variant="primary"
        >
          更新信息
        </Button>

        {loadingGetProduct || loadingUpdate ? <Loader /> : null}
      </Form>
    </FormContainer>
  );
};

export default ProductEditScreen;

import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import Demand from "../components/Demand";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useParams } from "react-router-dom";
import Paginate from "../components/DemandPaginate";
import { Link } from "react-router-dom";

import { useGetDemandsQuery } from "../slices/demandsApiSlice";
const DemandScreen = () => {
  const { pageNumber, keyword } = useParams();
  const { data, isLoading, error } = useGetDemandsQuery({
    keyword,
    pageNumber,
  });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <h1>
            最新的需求{"   "}
            <Button
              variant="primary"
              as={Link}
              to="/"
              style={{ fontSize: "0.8esm" }}
            >
              查看商品
            </Button>
          </h1>

          <Row>
            {data.demands.map((demand) => (
              <Col key={demand._id} sm={12} md={6} lg={4} xl={3}>
                <Demand demand={demand} />
              </Col>
            ))}
          </Row>
          <Paginate
            pages={data.pages}
            page={data.page}
            keyword={keyword ? keyword : ""}
          />
        </>
      )}
    </>
  );
};

export default DemandScreen;

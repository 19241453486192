import { apiSlice } from "./apiSlice";
import { USERS_URL } from "../constants";

export const usersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (data) => ({
				url: `${USERS_URL}/auth`,
				method: "POST",
				body: data,
			}),
		}),
		register: builder.mutation({
			query: (data) => ({
				url: `${USERS_URL}`,
				method: "POST",
				body: data,
			}),
		}),
		activate: builder.mutation({
			query: (token) => ({
				url: `${USERS_URL}/activate/${token}`,
				method: "POST",
			}),
		}),
		logout: builder.mutation({
			query: () => ({
				url: `${USERS_URL}/logout`,
				method: "POST",
			}),
		}),
		uploadUserImage: builder.mutation({
			query: (data) => ({
				url: `/api/upload`,
				method: "POST",
				body: data,
			}),
		}),
		getUserDetails: builder.query({
			query: (userId) => ({
				url: `${USERS_URL}/${userId}`,
			}),
			keepUnusedDataFor: 5,
		}),
		updateUser: builder.mutation({
			query: (data) => ({
				url: `${USERS_URL}/${data.userId}`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["User"],
		}),
		forgetPassword: builder.mutation({
			query: (email) => ({
				url: `${USERS_URL}/forget`,
				method: "POST",
				body: email,
			}),
		}),
		resetPassword: builder.mutation({
			query: (token) => ({
				url: `${USERS_URL}/reset/${token}`,
				method: "POST",
			}),
		}),
	}),
});

export const {
	useLoginMutation,
	useLogoutMutation,
	useRegisterMutation,
	useActivateMutation,
	useUploadUserImageMutation,
	useGetUserDetailsQuery,
	useUpdateUserMutation,
	useForgetPasswordMutation,
	useResetPasswordMutation,
} = usersApiSlice;

import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Row, Col, Image, ListGroup } from "react-bootstrap";
import { useGetDemandDetailsQuery } from "../slices/demandsApiSlice";
import Loader from "../components/Loader";
import Message from "../components/Message";
import FormContainer from "../components/FormContainer";

const DemandDetailScreen = () => {
  const { id: demandId } = useParams();
  const { data: demand, isLoading, error } = useGetDemandDetailsQuery(demandId);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <FormContainer>
          <Row>
            <Col>
              {demand.image ? (
                <Image src={demand.image} alt="需求图片" fluid />
              ) : (
                <Image
                  src={"/images/demand.jpg"}
                  alt="没有展示需求图片"
                  fluid
                />
              )}
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h3>{demand.name}</h3>
                </ListGroup.Item>
                <ListGroup.Item>需求描述： {demand.description}</ListGroup.Item>
                <ListGroup.Item>
                  <Link
                    to={`/users/${demand.user}`}
                    className="btn btn-dark my-3"
                  >
                    联系买家
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </FormContainer>
      )}
    </>
  );
};

export default DemandDetailScreen;

import React from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
const Product = ({ product }) => {
  return (
    <Card className="my-3 p-3 rounded">
      <Link to={`/products/${product._id}`}>
        <div className="image-wrapper">
          <Card.Img src={product.image} alt={product.name} variant="top" />
        </div>
      </Link>

      <Card.Body>
        <Link to={`/products/${product._id}`}>
          <Card.Title as="div">
            <strong>{product.name}</strong>
          </Card.Title>
        </Link>

        <Card.Text as="h3">¥{product.price}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Product;

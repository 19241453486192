import { PRODUCTS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const productsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getProducts: builder.query({
			query: ({ keyword, pageNumber }) => ({
				url: PRODUCTS_URL,
				params: { keyword, pageNumber },
			}),
			keepUnusedDataFor: 5,
		}),
		getProductDetails: builder.query({
			query: (productId) => ({
				url: `${PRODUCTS_URL}/${productId}`,
			}),
			keepUnusedDataFor: 5,
		}),
		uploadProductImage: builder.mutation({
			query: (data) => ({
				url: `/api/upload`,
				method: "POST",
				body: data,
			}),
		}),
		newProduct: builder.mutation({
			query: (data) => ({
				url: `${PRODUCTS_URL}`,
				method: "POST",
				body: data,
			}),
		}),
		getUserProducts: builder.query({
			query: (userId) => ({
				url: `${PRODUCTS_URL}/useritems/${userId}`,
			}),
			keepUnusedDataFor: 5,
		}),
		deleteProduct: builder.mutation({
			query: (productId) => ({
				url: `${PRODUCTS_URL}/${productId}`,
				method: "DELETE",
			}),
		}),
		updateProduct: builder.mutation({
			query: (data) => ({
				url: `${PRODUCTS_URL}/${data.productId}`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["Product"],
		}),
	}),
});

export const {
	useGetProductsQuery,
	useGetProductDetailsQuery,
	useUploadProductImageMutation,
	useNewProductMutation,
	useGetUserProductsQuery,
	useDeleteProductMutation,
	useUpdateProductMutation,
} = productsApiSlice;

import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Row, Col, Image, ListGroup } from "react-bootstrap";
import { useGetProductDetailsQuery } from "../slices/productsApiSlice";
import Loader from "../components/Loader";
import Message from "../components/Message";
import FormContainer from "../components/FormContainer";

const ProductScreen = () => {
	const { id: productId } = useParams();
	const {
		data: product,
		isLoading,
		error,
	} = useGetProductDetailsQuery(productId);

	return (
		<>
			{isLoading ? (
				<Loader />
			) : error ? (
				<Message variant="danger">
					{error?.data?.message || error.error}
				</Message>
			) : (
				<FormContainer>
					<Row>
						<Col>
							<Image src={product.image} alt={product.name} fluid />
							<ListGroup variant="flush">
								<ListGroup.Item>
									<h3>{product.name}</h3>
								</ListGroup.Item>
								<ListGroup.Item>价格： ¥{product.price}</ListGroup.Item>
								<ListGroup.Item>
									商品描述： {product.description}
								</ListGroup.Item>
								<ListGroup.Item>
									<Link
										to={`/users/${product.user}`}
										className="btn btn-dark my-3"
									>
										联系卖家
									</Link>
								</ListGroup.Item>
							</ListGroup>
						</Col>
					</Row>
				</FormContainer>
			)}
		</>
	);
};

export default ProductScreen;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";

import {
  useNewDemandMutation,
  useUploadDemandImageMutation,
} from "../slices/demandsApiSlice";
import { toast } from "react-toastify";

const NewDemandScreen = () => {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");

  const [uploadDemandImage, { isLoading: loadingUpload }] =
    useUploadDemandImageMutation();

  const navigate = useNavigate();

  const [newItem, { isLoading }] = useNewDemandMutation();

  const { userInfo } = useSelector((state) => state.auth);
  const user = userInfo._id;
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await newItem({
        name,
        description,
        image,
        user,
      }).unwrap();
      toast.success(res.message);
      navigate("/demands");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };
  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const res = await uploadDemandImage(formData).unwrap();
      toast.success(res.message);
      setImage(res.image);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };
  return (
    <FormContainer>
      <h1>上传一个需求</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group className="my-2" controlId="name">
          <Form.Label>需求名称</Form.Label>
          <Form.Control
            type="text"
            placeholder="输入需求名称"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="image">
          <Form.Label>需求图片（选填）</Form.Label>
          <Form.Control
            type="text"
            placeholder="在下面上传图片文件"
            value={image}
            onChange={(e) => setImage(e.target.value)}
            readOnly={true}
          ></Form.Control>
          <Form.Control
            label="选择文件"
            onChange={uploadFileHandler}
            type="file"
          ></Form.Control>
          {loadingUpload && <Loader />}
        </Form.Group>
        <Form.Group className="my-2" controlId="description">
          <Form.Label>需求描述</Form.Label>
          <Form.Control
            type="text"
            placeholder="输入需求描述"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button disabled={isLoading} type="submit" variant="primary">
          上传
        </Button>

        {isLoading && <Loader />}
      </Form>
    </FormContainer>
  );
};

export default NewDemandScreen;

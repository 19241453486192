import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useActivateMutation } from "../slices/usersApiSlice";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { toast } from "react-toastify";
import FormContainer from "../components/FormContainer";

const ActivateScreen = () => {
	const { token } = useParams();
	const [activate, { isLoading, err }] = useActivateMutation(token);

	const submitHandler = async (e) => {
		e.preventDefault();
		try {
			const res = await activate(token).unwrap();
			toast.success(res.message);
		} catch (err) {
			toast.error(err?.data?.message || err.error);
		}
	};
	return (
		<>
			{isLoading ? (
				<Loader />
			) : err ? (
				<Message variant="danger">{err?.data?.message || err.error}</Message>
			) : (
				<FormContainer>
					<Button
						disabled={isLoading}
						onClick={submitHandler} // Change onSubmit to onClick
						variant="primary"
					>
						点击这个按钮来确认您的账户
					</Button>
				</FormContainer>
			)}
		</>
	);
};
export default ActivateScreen;

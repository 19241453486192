import { useParams } from "react-router-dom";
import { Row, Image, ListGroup } from "react-bootstrap";
import { useGetUserDetailsQuery } from "../slices/usersApiSlice";
import Loader from "../components/Loader";
import Message from "../components/Message";
import FormContainer from "../components/FormContainer";

const UserScreen = () => {
  const { id: userId } = useParams();
  const { data: user, isLoading, error } = useGetUserDetailsQuery(userId);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <FormContainer>
          <Row>
            {user.image ? (
              <Image src={user.image} alt="微信账户二维码" fluid />
            ) : (
              <>
                <Image
                  src={"/images/user.png"}
                  alt="没有上传需求二维码"
                  fluid
                />
                <h1>用户没有上传二维码</h1>
              </>
            )}
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h3>{user.email}</h3>
              </ListGroup.Item>
              <ListGroup.Item>其他联系方式：{user.altContact}</ListGroup.Item>
            </ListGroup>
          </Row>
        </FormContainer>
      )}
    </>
  );
};

export default UserScreen;

import React from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
const Demand = ({ demand }) => {
	return (
		<Card className="my-3 p-3 rounded">
			<Link to={`/demands/${demand._id}`}>
				<div className="image-wrapper">
					{demand.image ? (
						<Card.Img src={demand.image} alt="需求图片" variant="top" />
					) : (
						<Card.Img src={"/images/demand.jpg"} variant="top" />
					)}
				</div>
			</Link>

			<Card.Body>
				<Link to={`/demands/${demand._id}`}>
					<Card.Title as="div">
						<strong>{demand.name}</strong>
					</Card.Title>
				</Link>
			</Card.Body>
		</Card>
	);
};

export default Demand;

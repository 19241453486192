import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";

import {
	useRegisterMutation,
	useUploadUserImageMutation,
} from "../slices/usersApiSlice";
import { toast } from "react-toastify";

const RegisterScreen = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [image, setImage] = useState("");
	const [altContact, setAltContact] = useState("");

	const [uploadUserImage, { isLoading: loadingUpload }] =
		useUploadUserImageMutation();

	const navigate = useNavigate();

	const [register, { isLoading }] = useRegisterMutation();

	const { userInfo } = useSelector((state) => state.auth);

	useEffect(() => {
		if (userInfo) {
			navigate("/");
		}
	}, [navigate, userInfo]);

	const submitHandler = async (e) => {
		e.preventDefault();
		if (!email.includes("@ruc.edu.cn")) {
			setEmail("");
			toast.error("请用@ruc.edu.cn邮箱注册");
		} else if (password !== confirmPassword) {
			toast.error("密码不匹配");
		} else {
			try {
				const res = await register({
					email,
					password,
					image,
					altContact,
				}).unwrap();
				toast.success("一个账户激活邮件已经发送至您的邮箱");
			} catch (err) {
				toast.error(err?.data?.message || err.error);
			}
		}
	};
	const uploadFileHandler = async (e) => {
		const formData = new FormData();
		formData.append("image", e.target.files[0]);
		try {
			const res = await uploadUserImage(formData).unwrap();
			toast.success(res.message);
			setImage(res.image);
		} catch (err) {
			toast.error(err?.data?.message || err.error);
		}
	};
	return (
		<FormContainer>
			<h1>注册</h1>
			<Form onSubmit={submitHandler}>
				<Form.Group className="my-2" controlId="email">
					<Form.Label>电子邮件 (如2020123456@ruc.edu.cn)</Form.Label>
					<Form.Control
						type="email"
						placeholder="只能用@ruc.edu.cn邮箱注册"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group className="my-2" controlId="password">
					<Form.Label>密码</Form.Label>
					<Form.Control
						type="password"
						placeholder="输入密码"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					></Form.Control>
				</Form.Group>
				<Form.Group className="my-2" controlId="confirmPassword">
					<Form.Label>确认密码</Form.Label>
					<Form.Control
						type="password"
						placeholder="确认密码"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group controlId="image">
					<Form.Label>微信用户二维码（选填）</Form.Label>
					<Form.Control
						type="text"
						placeholder="在下面上传微信账号二维码"
						value={image}
						onChange={(e) => setImage(e.target.value)}
						readOnly={true}
					></Form.Control>
					<Form.Control
						label="选择图片文件"
						onChange={uploadFileHandler}
						type="file"
					></Form.Control>
					{loadingUpload && <Loader />}
				</Form.Group>
				<Form.Group className="my-2" controlId="altContact">
					<Form.Label>其他联系方式（选填）</Form.Label>
					<Form.Control
						type="text"
						placeholder="输入其他联系方式"
						value={altContact}
						onChange={(e) => setAltContact(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Button disabled={isLoading} type="submit" variant="primary">
					注册
				</Button>

				{isLoading && <Loader />}
			</Form>

			<Row className="py-3">
				<Col>
					已经有账号？ <Link to={"/login"}>登入</Link>
				</Col>
			</Row>
		</FormContainer>
	);
};

export default RegisterScreen;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ActivateScreen from "./screens/ActivateScreen";
import UserScreen from "./screens/UserScreen";
import NewItemScreen from "./screens/NewItemScreen";
import ListScreen from "./screens/ListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import ForgetScreen from "./screens/ForgetScreen";
import ResetScreen from "./screens/ResetScreen";
import DemandScreen from "./screens/DemandSreen";
import NewDemandScreen from "./screens/NewDemandScreen";
import DemandDetailScreen from "./screens/DemandDetailScreen";
import DemandEditScreen from "./screens/DemandEditScreen";

import { Provider } from "react-redux";
import { store } from "./store";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<HomeScreen />} />
      <Route path="/page/:pageNumber" element={<HomeScreen />} />
      <Route path="/search/products/:keyword" element={<HomeScreen />} />
      <Route
        path="/search/products/:keyword/page/:pageNumber"
        element={<HomeScreen />}
      />

      <Route path="/demands" element={<DemandScreen />} />
      <Route path="/demands/page/:pageNumber" element={<DemandScreen />} />
      <Route path="/search/demands/:keyword" element={<DemandScreen />} />
      <Route
        path="/search/demands/:keyword/page/:pageNumber"
        element={<DemandScreen />}
      />
      <Route path="/products/:id" element={<ProductScreen />} />
      <Route path="/demands/:id" element={<DemandDetailScreen />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/register" element={<RegisterScreen />} />
      <Route path="/users/activate/:token" element={<ActivateScreen />} />
      <Route path="/users/:id" element={<UserScreen />} />
      <Route path="/users/edit/:id" element={<UserEditScreen />} />
      <Route path="/newitem" element={<NewItemScreen />} />
      <Route path="/newdemand" element={<NewDemandScreen />} />
      <Route path="/useritems/:id" element={<ListScreen />} />
      <Route path="/products/edit/:id" element={<ProductEditScreen />} />
      <Route path="/demands/edit/:id" element={<DemandEditScreen />} />
      <Route path="/forget" element={<ForgetScreen />} />
      <Route path="/reset/:token" element={<ResetScreen />} />
    </Route>
  )
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

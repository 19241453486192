import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { useParams } from "react-router-dom";

import {
  useGetDemandDetailsQuery,
  useUpdateDemandMutation,
  useUploadDemandImageMutation,
} from "../slices/demandsApiSlice";
import { toast } from "react-toastify";

const DemandEditScreen = () => {
  const { id: demandId } = useParams();
  const {
    data: demand,
    isLoading: loadingGetDemand,
    error,
  } = useGetDemandDetailsQuery(demandId);

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [uploadDemandImage, { isLoading: loadingUpload }] =
    useUploadDemandImageMutation();
  const navigate = useNavigate();

  const [updateDemand, { isLoading: loadingUpdate }] =
    useUpdateDemandMutation();

  const { userInfo } = useSelector((state) => state.auth);
  useEffect(() => {
    if (demand) {
      setName(demand.name);
      setImage(demand.image);
      setDescription(demand.description);
    }
  }, [demand]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (demand.user !== userInfo._id) {
      toast.error("只可以修改自己的需求信息");
      navigate(`/useritems/${userInfo._id}`);
    }
    try {
      const res = await updateDemand({
        demandId,
        name,
        description,
        image,
      }).unwrap();
      toast.success("已成功修改需求信息");
      navigate("/demands");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };
  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const res = await uploadDemandImage(formData).unwrap();
      toast.success(res.message);
      setImage(res.image);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };
  return (
    <FormContainer>
      <h1>修改需求信息</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group className="my-2" controlId="name">
          <Form.Label>需求名称</Form.Label>
          <Form.Control
            type="text"
            placeholder="输入需求名称"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="image">
          <Form.Label>需求图片</Form.Label>
          <Form.Control
            type="text"
            placeholder="在下面上传图片文件"
            value={image}
            onChange={(e) => setImage(e.target.value)}
            readOnly={true}
          ></Form.Control>
          <Form.Control
            label="选择文件"
            onChange={uploadFileHandler}
            type="file"
          ></Form.Control>
          {loadingUpload && <Loader />}
        </Form.Group>
        <Form.Group className="my-2" controlId="description">
          <Form.Label>需求描述</Form.Label>
          <Form.Control
            type="text"
            placeholder="输入商品描述"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button
          disabled={loadingGetDemand || loadingUpdate}
          type="submit"
          variant="primary"
        >
          更新信息
        </Button>

        {loadingGetDemand || loadingUpdate ? <Loader /> : null}
      </Form>
    </FormContainer>
  );
};

export default DemandEditScreen;

import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import Message from "../components/Message";
import Loader from "../components/Loader";
import {
  useGetUserProductsQuery,
  useDeleteProductMutation,
} from "../slices/productsApiSlice";
import {
  useGetUserDemandsQuery,
  useDeleteDemandMutation,
} from "../slices/demandsApiSlice";
import { toast } from "react-toastify";

const ListScreen = () => {
  const [deleteProduct, { isLoading: loadingDeleteProduct }] =
    useDeleteProductMutation();
  const {
    data: products,
    isLoading: loadingProducts,
    error: getProductsError,
    refetch: refetchProducts,
  } = useGetUserProductsQuery();

  const [deleteDemand, { isLoading: loadingDeleteDemand }] =
    useDeleteDemandMutation();

  const {
    data: demands,
    isLoading: loadingDemands,
    error: getDemandsError,
    refetch: refetchdemands,
  } = useGetUserDemandsQuery();

  const deleteProductHandler = async (id) => {
    if (window.confirm("Are you sure")) {
      try {
        await deleteProduct(id);
        refetchProducts();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };
  const deleteDemandHandler = async (id) => {
    if (window.confirm("Are you sure")) {
      try {
        await deleteDemand(id);
        refetchdemands();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h1>我的商品</h1>
        </Col>
      </Row>

      {loadingProducts ? (
        <Loader />
      ) : getProductsError ? (
        <Message variant="danger">{getProductsError}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>名称</th>
                <th>价格</th>
                <th>修改</th>
                <th>删除</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product._id}>
                  <td>
                    <Link to={`/products/${product._id}`}>{product._id}</Link>
                  </td>
                  <td>{product.name}</td>
                  <td>¥{product.price}</td>
                  <td>
                    <LinkContainer to={`/products/edit/${product._id}`}>
                      <Button variant="danger" className="btn-sm">
                        <FaEdit />
                      </Button>
                    </LinkContainer>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteProductHandler(product._id)}
                    >
                      <FaTrash />
                    </Button>
                    {loadingDeleteProduct && <Loader />}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* PAGINATE PLACEHOLDER */}
        </>
      )}

      <Row className="align-items-center">
        <Col>
          <h1>我的需求</h1>
        </Col>
      </Row>

      {loadingDemands ? (
        <Loader />
      ) : getDemandsError ? (
        <Message variant="danger">{getDemandsError}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>名称</th>
                <th>修改</th>
                <th>删除</th>
              </tr>
            </thead>
            <tbody>
              {demands.map((demand) => (
                <tr key={demand._id}>
                  <td>
                    <Link to={`/demands/${demand._id}`}>{demand._id}</Link>
                  </td>
                  <td>{demand.name}</td>
                  <td>
                    <LinkContainer to={`/demands/edit/${demand._id}`}>
                      <Button variant="danger" className="btn-sm">
                        <FaEdit />
                      </Button>
                    </LinkContainer>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteDemandHandler(demand._id)}
                    >
                      <FaTrash />
                    </Button>
                    {loadingDeleteDemand && <Loader />}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* PAGINATE PLACEHOLDER */}
        </>
      )}
    </>
  );
};

export default ListScreen;

import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { FaUser, FaBox, FaCloudUploadAlt, FaEdit } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../slices/authSlice";

import { useLogoutMutation } from "../slices/usersApiSlice";
import React from "react";
import ProductSearchBox from "./ProductSearchBox";
import DemandSearchBox from "./DemandSearchBox";
const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [logoutApiCall] = useLogoutMutation();
  const { userInfo } = useSelector((state) => state.auth);
  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      navigate("/login");
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <header>
      <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
        <Container>
          <Navbar.Brand href="/">回到主页</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {location.pathname.includes("/demands") ? (
                <DemandSearchBox />
              ) : (
                <ProductSearchBox />
              )}
              {userInfo ? (
                <>
                  <LinkContainer to="/newdemand">
                    <Nav.Link>
                      <FaCloudUploadAlt /> 上传需求
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/newitem">
                    <Nav.Link>
                      <FaCloudUploadAlt /> 上传商品
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to={`/users/${userInfo._id}`}>
                    <Nav.Link>
                      <FaUser /> 个人信息
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to={`/users/edit/${userInfo._id}`}>
                    <Nav.Link>
                      <FaEdit />
                      编辑个人信息
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to={`/useritems/${userInfo._id}`}>
                    <Nav.Link>
                      <FaBox /> 我的
                    </Nav.Link>
                  </LinkContainer>
                  <NavDropdown title={userInfo.email} id="username">
                    <NavDropdown.Item onClick={logoutHandler}>
                      登出
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <LinkContainer to="/login">
                  <Nav.Link>
                    <i className="fas fa-user"></i> 登入或注册
                  </Nav.Link>
                </LinkContainer>
              )}
              ;
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
